<template>
	<v-row class="my-4">
		<v-col cols="12" md="8">
			<v-card class="mx-sm-8">
				<v-card-title>{{user.fullname}}</v-card-title>
				<v-card-subtitle>{{user.function}}</v-card-subtitle>
				<v-list>
					<v-list-item three-line>
						<v-list-item-avatar tile size="96">
							<v-icon color="secondary" size="96">mdi-account</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-subtitle>{{user.orga.name}}</v-list-item-subtitle>
							<v-list-item-subtitle>{{user.centre.description}}</v-list-item-subtitle>
							<v-list-item-subtitle>{{user.centre.address.street}}</v-list-item-subtitle>
							<v-list-item-subtitle>{{user.centre.address.codpost}}</v-list-item-subtitle>
							<v-list-item-subtitle>{{user.centre.address.city}}</v-list-item-subtitle>
							<v-list-item-subtitle>{{user.mail}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card>
		</v-col>

		<v-col cols="12" md="4">
			<v-card class="mx-sm-4" v-if="$store.getters.claims.roles.includes('annuaire.administrateur') || $store.getters.claims.roles.includes('gsi.' + user.orga.id)">
				<v-card-title>Outils</v-card-title>
				<v-list dense>
					<v-list-item><v-list-item-content><v-list-item-title><v-btn color="primary" @click="$store.dispatch('editUser', id)" text><v-icon>mdi-pencil</v-icon> Modifier</v-btn></v-list-item-title></v-list-item-content></v-list-item>
					<v-list-item><v-list-item-content><v-list-item-title><v-btn color="primary" @click="$store.dispatch('removeUser', user)" text><v-icon>mdi-delete</v-icon> Supprimer</v-btn></v-list-item-title></v-list-item-content></v-list-item>
				</v-list>
			</v-card>
		</v-col>
	</v-row>

</template>

<script>
export default {
	props: {
		id: String
	},
	data: () => ({
		user: {
			fullname: "",
			function: "",
			mail: "",
			orga : {
				description: ""
			},
			centre: {
				name: "",
				address: {
					street: "",
					codpost: "",
					city: ""
				}
			}
		}
	}),
	created() {
		this.init();
	},
	watch: {
		id() {
			this.init();
		}
	},
	methods: {
		init() {
			this.$axios.get("/users/" + this.id).then((response) => {
				if(response.data) {
					this.user = response.data;
				}
			});
		}
	}
}
</script>
