import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';
import Role from '@/views/Role';
import User from '@/views/User';
import Group from '@/views/Group';
import Organisme from '@/views/Organisme';
import Centre from '@/views/Centre';

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/role/:id/',
		name: 'role',
		component: Role,
		props: true
	},
	{
		path: '/group/:id/',
		name: 'group',
		component: Group,
		props: true
	},
	{
		path: '/user/:id/',
		name: 'user',
		component: User,
		props: true
	},
	{
		path: '/organisme/:id/',
		name: 'organisme',
		component: Organisme,
		props: true
	},
	{
		path: '/centre/:id/',
		name: 'centre',
		component: Centre,
		props: true
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
