<template>
	<div>
		<v-dialog v-model="dialog" max-width="600">
			<v-card>
				<v-card-title class="text-h6 primary">
					ATTENTION !
				</v-card-title>
				<v-card-text>
					<br />Vous êtes sur le point de déplacer votre selection de structures vers l'organisme
					<b>{{target.fullname}}</b>.
					<br /><br />
					<v-alert border="left" color="error" dark>
						<ul>
							<li>Tout le personnel appartement à ces structures sera transféré</li>
							<li>Les actions de formation liées à ces structures seront créées dans l'organisme cible si elles
								n'existent pas</li>
							<li>Les sessions de formation ayant lieu dans ces structures seront déplacées également</li>
						</ul>
					</v-alert>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn outlined color="primary" text @click="dialog = false">Annuler</v-btn>
					<v-btn color="error" text @click="onMove()" :disabled="moving"><v-progress-circular color="primary" indeterminate v-show="moving"></v-progress-circular><span v-show="!moving">Confirmer</span></v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-row class="my-4">
			<v-col cols="12" md="8">
				<v-card class="mx-sm-4">
					<v-card-title>{{orga.fullname}}</v-card-title>
					<v-list>
						<v-list-item three-line>
							<v-list-item-avatar tile size="96">
								<v-icon color="secondary" size="96">mdi-domain</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<!-- <v-list-item-subtitle>id: {{orga.id}}</v-list-item-subtitle> -->
								<v-list-item-subtitle>{{orga.address.name}}</v-list-item-subtitle>
								<v-list-item-subtitle>{{orga.address.street}}</v-list-item-subtitle>
								<v-list-item-subtitle>{{orga.address.postcod}}</v-list-item-subtitle>
								<v-list-item-subtitle>{{orga.address.city}}</v-list-item-subtitle>
								<v-list-item-subtitle>Tél : {{orga.phone}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>

			<v-col cols="12" md="4">
				<v-card class="mx-sm-4"
					v-if="$store.getters.claims.roles.includes('annuaire.administrateur') || $store.getters.claims.roles.includes('gsi.' + id)">
					<v-card-title>Outils</v-card-title>
					<v-list dense>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<v-btn color="primary" @click="$store.dispatch('editOrganisme', id)" text>
										<v-icon>mdi-pencil</v-icon> Modifier
									</v-btn>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn color="primary" v-bind="attrs" v-on="on" text>
												<v-icon>mdi-plus-box</v-icon> Ajouter une structure
											</v-btn>
										</template>

										<v-list>
											<v-subheader>TYPE DE STRUCTURE</v-subheader>
											<v-list-item>
												<v-list-item-content>
													<v-list-item-title><a color="primary" text
															@click="$store.dispatch('createCentre', {idStructure: id, type: 'dafcoCreteilStructureAgenceCommerciale'})">Agence
															commerciale</a></v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-list-item>
												<v-list-item-content>
													<v-list-item-title><a color="primary" text
															@click="$store.dispatch('createCentre', {idStructure: id, type: 'dafcoCreteilStructureAgenceComptable'})">Agence
															comptable</a></v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-list-item>
												<v-list-item-content>
													<v-list-item-title><a color="primary" text
															@click="$store.dispatch('createCentre', {idStructure: id, type: 'dafcoCreteilStructureCentre'})">Centre
															de formation</a></v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-list-item>
												<v-list-item-content>
													<v-list-item-title><a color="primary" text
															@click="$store.dispatch('createCentre', {idStructure: id, type: 'dafcoCreteilStructureAccueil'})">Point
															accueil</a></v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-list-item>
												<v-list-item-content>
													<v-list-item-title><a color="primary" text
															@click="$store.dispatch('createCentre', {idStructure: id, type: 'dafcoCreteilStructureService'})">Service
															administratif</a></v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list>
									</v-menu>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="$store.getters.claims.roles.includes('annuaire.administrateur')">
							<v-list-item-content>
								<v-list-item-title>
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn color="primary" v-bind="attrs" v-on="on" text
												:disabled="selected.length < 1 || organismes < 1">
												<v-icon>mdi-domain-off</v-icon> Déplacer vers ...
											</v-btn>
										</template>

										<v-list>
											<v-subheader>ORGANISME CIBLE</v-subheader>
											<v-list-item v-for="o in organismes" :key="o.id">
												<v-list-item-content>
													<v-list-item-title><a color="primary" text @click="showConfirm(o)">{{o.fullname}}</a>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list>
									</v-menu>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
		</v-row>

		<v-data-table v-model="selected" show-select :headers="headers" :items="items" :items-per-page="-1" dense
			class="elevation-1 mr-4" @click:row="onClickRow">
			<template v-slot:item.fullname="{ item }">
				<v-icon color="primary">mdi-home-city</v-icon> {{item.description}}
			</template>
			<template v-slot:item.infos="{ item }">{{formatType(item.type)}} ({{item.orga}})</template>
		</v-data-table>
	</div>
</template>

<script>
export default {
	props: {
		id: String
	},
	data: () => ({
		dialog: false,
		moving: false,
		target: "",
		headers: [
			{ text: 'Nom', value: 'fullname', align: 'start' },
			{ text: 'Description', value: 'infos', align: 'start' },
			{ text: 'Mail', value: 'mail' }
		],
		items: [],
		selected: [],
		organismes: [],
		orga: {
			id: "",
			name: "",
			fullname: "",
			phone: "",
			address: {
				name: "",
				street: "",
				codpost: "",
				city: ""
			}
		}
	}),
	created() {
		this.init();
	},
	watch: {
		id() {
			this.init()
		}
	},
	methods: {
		init() {
			this.selected = [];
			this.$axios.get("/organismes/" + this.id + "/centres").then((response) => {
				if (response.data) {
					this.orga = response.data.orga;
					this.items = response.data.centres;
				}
			});
			this.$axios.get("/organismes").then((response) => {
				if (response.data) {
					this.organismes = response.data;
				}
			});
		},
		formatType(type) {
			switch (type) {
				case "dafcoCreteilStructureAgenceCommerciale":
					return "Agence commerciale";
				case "dafcoCreteilStructureAccueil":
					return "Point accueil";
				case "dafcoCreteilStructureAgenceComptable":
					return "Agence comptable";
				case "dafcoCreteilStructureCentre":
					return "Centre";
				case "dafcoCreteilStructureService":
					return "Service";
				default:
					return type;
			}
		},
		onClickRow(item) {
			this.$router.push({ name: 'centre', params: { id: item.id }, query: { token: this.$store.getters.jwt } }).catch(e => { (e.name === 'NavigationDuplicated' ? '' : console.log(e)) });
		},
		showConfirm(target) {
			this.target = target;
			this.dialog = true;
		},
		onMove() {
			let list = [];
			for (const el of this.selected) {
				list.push(el.id);
			}

			this.moving = true;
			this.$axios.post("/organismes/" + this.target.id + "/centres", list).then(() => {
				this.$router.go(); // refresh the page
				this.dialog = false;
				this.moving = false;
			});
		}
	}
}
</script>
