<template>
	<div>
		<v-card class="my-4 mx-sm-8">
			<v-card-title>{{role.name}}</v-card-title>
			<v-list>
				<v-list-item two-line>
					<v-list-item-avatar tile size="96">
						<v-icon color="secondary" size="96">mdi-account-hard-hat</v-icon>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-subtitle>{{role.description}}</v-list-item-subtitle>
						<v-list-item-subtitle>id: {{role.id}}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card>

		<v-data-table :headers="headers" :items="items" :items-per-page="-1" dense class="elevation-1 mr-4" @click:row="onClickRow">
			<template v-slot:item.fullname="{ item }">
				<v-icon color="primary">mdi-account</v-icon> {{ item.fullname }}
			</template>
		</v-data-table>
	</div>
</template>

<script>
export default {
	props: {
		id: String
	},
	data: () => ({
		headers: [
			{ text: 'Nom', value: 'fullname', align: 'start' },
			{ text: 'Description', value: 'description', align: 'start' },
			{ text: 'Mail', value: 'mail'}
		],
		items: [],
		role: {
			id: "",
			name: "",
			description: ""
		}
	}),
	created() {
		this.init();
	},
	watch: {
		id() {
			this.init()
		}
	},
	methods: {
		init() {
			this.$axios.get("/roles/" + this.id + "/users").then((response) => {
				if(response.data) {
					this.role = response.data.role;
					this.items = response.data.users;
				}
			});
		},
		onClickRow(item) {
			this.$router.push({name: 'user', params: {id: item.uid}, query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
		}
	}
}
</script>
