import Vue from 'vue';
import Vuex from 'vuex';
import * as jose from 'jose';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		message: {
			text: "",
			type: "info" // error, info, success, warning
		},
		jwt: "",
		claims: {},
		showModOrganisme: null,
		showModCentre: null,
		showModUser: null,
		showRemoveUser: null,
		showRemoveCentre: null,
		editedOrganisme: {},
		editedCentre: {lieu: {}},
		editedUser: {orga: {}, centre: {}, applications: Array(), roles: Array()}
	},
	getters: {
		message: state => {
			return state.message;
		},
		jwt: state => {
			return state.jwt;
		},
		claims: state => {
			return state.claims;
		},
		showModOrganisme: state => {
			return state.showModOrganisme;
		},
		editedOrganisme: state => {
			return state.editedOrganisme;
		},
		showModCentre: state => {
			return state.showModCentre;
		},
		editedCentre: state => {
			return state.editedCentre;
		},
		showModUser: state => {
			return state.showModUser;
		},
		showRemoveUser: state => {
			return state.showRemoveUser;
		},
		showRemoveCentre: state => {
			return state.showRemoveCentre;
		},
		editedUser: state => {
			return state.editedUser;
		}
	},
	mutations: {
		MESSAGE(state, payload) {
			state.message = payload;
		},
		JWT(state, payload) {
			const claims = jose.decodeJwt(payload);
			state.claims = claims;
			if(!Array.isArray(claims.roles)) claims.roles = Array();
			state.jwt = payload;
		},
		SHOW_MOD_ORGANISME(state, payload) {
			state.showModOrganisme = payload;
		},
		EDIT_ORGANISME(state, payload) {
			state.editedOrganisme = payload
		},
		SHOW_MOD_CENTRE(state, payload) {
			state.showModCentre = payload;
		},
		SHOW_REMOVE_CENTRE(state, payload) {
			state.showRemoveCentre = payload;
		},
		EDIT_CENTRE(state, payload) {
			state.editedCentre = payload
		},
		SHOW_MOD_USER(state, payload) {
			state.showModUser = payload;
		},
		SHOW_REMOVE_USER(state, payload) {
			state.showRemoveUser = payload;
		},
		EDIT_USER(state, payload) {
			state.editedUser = payload
		}
	},
	actions: {
		jwt(context, payload) {
			context.commit('JWT', payload);
		},
		createOrganisme(context) {
			context.commit('EDIT_ORGANISME', {id: ''});
			context.commit('SHOW_MOD_ORGANISME', 'add');
		},
		editOrganisme(context, id) {
			Vue.axios.get("/organismes/" + id).then((response) => {
				if(response.data) {
					context.commit('EDIT_ORGANISME', response.data);
					context.commit('SHOW_MOD_ORGANISME', 'edit');
				}
			});
		},
		createCentre(context, centre) {
			centre.id = '';
			centre.typeAccessible = Array();
			centre.equipements = Array();
			centre.lieu = {};
			context.commit('EDIT_CENTRE', centre);
			context.commit('SHOW_MOD_CENTRE', 'add');
		},
		editCentre(context, id) {
			Vue.axios.get("/centres/" + id).then((response) => {
				if(response.data) {
					context.commit('EDIT_CENTRE', response.data);
					context.commit('SHOW_MOD_CENTRE', 'edit');
				}
			});
		},
		createUser(context, idcentre) {
			var idorga = (idcentre ? idcentre.substr(0, idcentre.length - 3) : null);
			context.commit('EDIT_USER', {uid: '', password: '', orga: {id: idorga}, centre: {id: idcentre}, applications: Array(), roles: Array()});
			context.commit('SHOW_MOD_USER', 'add');
		},
		removeUser(context, user) {
			context.commit('SHOW_REMOVE_USER', user);
		},
		removeCentre(context, centre) {
			context.commit('SHOW_REMOVE_CENTRE', centre);
		},
		editUser(context, id) {
			Vue.axios.get("/users/" + id).then((response) => {
				if(response.data) {
					context.commit('EDIT_USER', response.data);
					context.commit('SHOW_MOD_USER', 'edit');
				}
			});
		}
	}
})
