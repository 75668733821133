<template>
	<v-app>
		<v-snackbar style="z-index: 500" :timeout="-1" :value="message.text != ''" :color="message.type" absolute centered>
			{{message.text}}
			<template v-slot:action="{ attrs }"><v-btn v-bind="attrs" color="black" text @click="closeMsg">Ok</v-btn></template>
		</v-snackbar>
		<modal-organisme @refresh="reload()" /><modal-centre /><modal-user @refresh="reload()" /><modal-user-remove @refresh="reload()" /><modal-centre-remove @refresh="reload()" />
		<v-app-bar app color="primary" >
			<img src="https://intranet.forpro-creteil.org/GetImage?fonction=icone&nom=annuaire&size=36" alt="logo">
			<v-toolbar-title class="white--text d-none d-md-flex">Annuaire académique</v-toolbar-title>
			<v-spacer class="d-none d-md-flex"></v-spacer>

			<v-col  class="mt-6">
				<v-text-field outlined clearable @keyup.enter="onSearch" @click:clear="keywords = null; onSearch()" label="Rechercher" type="text" v-model="keywords" dark dense></v-text-field>
			</v-col>
			<v-btn class="mr-4" color="secondary" @click="onSearch"><v-icon>mdi-magnify</v-icon></v-btn>

			<v-btn v-if="$store.getters.claims.roles.includes('annuaire.administrateur')" class="mr-4" color="secondary" @click="$store.dispatch('createOrganisme')">
				<v-icon>mdi-domain-plus</v-icon>
				<span class="mr-2 d-none d-md-flex"> Ajouter un organisme</span>
			</v-btn>

			<v-btn color="secondary" @click="$store.dispatch('editUser', $store.getters.claims.sub)">
				<v-icon>mdi-account-wrench</v-icon>
				<span class="mr-2 d-none d-md-flex">Gérer mon compte</span>
			</v-btn>
		</v-app-bar>
		<v-main>
			<v-row>
				<v-col cols="12" sm="4">
					<v-card width="150vw" height="90vh" class="overflow-auto">
						<v-card-text>
							<v-treeview :open="openItem" :active.sync="activeItem" :items="items" activatable dense return-object item-key="id" transition>
								<template v-slot:prepend="{ item, open }">
									<v-icon v-if="item.type === 'dir'" color="secondary">
										{{ open ? 'mdi-folder-open' : 'mdi-folder' }}
									</v-icon>
									<v-icon v-else color="primary">
										{{ getIcon(item.type) }}
									</v-icon>
								</template>
							</v-treeview>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="8">
					<router-view />
				</v-col>
			</v-row>
		</v-main>
	</v-app>
</template>

<script>
import modalOrganisme from '@/views/modals/OrganismeForm';
import modalCentre from '@/views/modals/CentreForm';
import modalUser from '@/views/modals/UserForm';
import modalUserRemove from '@/views/modals/UserRemove';
import modalCentreRemove from '@/views/modals/CentreRemove';

export default {
	name: 'App',
	components: {
		modalOrganisme,
		modalCentre,
		modalUser,
		modalUserRemove,
		modalCentreRemove
	},
	data: () => ({
		keywords: "",
		openItem: [],
		activeItem: [],
		items: []
	}),
	created() {
		this.$store.dispatch('jwt', this.$route.query.token);
		this.$axios.defaults.headers.common['Authorization'] = "Bearer " + this.$route.query.token;
		this.reload();
	},
	methods: {
		reload() {
			this.$axios.get("/nodes").then((response) => {
				if(response.data) {
					this.items = response.data;
					if(this.$route.params.id) {
						for(var a of this.items) {
							if(a.id === this.$route.params.id) {
								this.activeItem = [a];
								this.openItem = [a];
								break;
							} else if(a.children) {
								for(var b of a.children) {
									if(b.id === this.$route.params.id) {
										this.activeItem = [b];
										this.openItem = [a, b];
										break;
									} else if(b.children) {
										for(var c of b.children) {
											if(c.id === this.$route.params.id) {
												this.activeItem = [c];
												this.openItem = [a, b, c];
												break;
											} else if(c.children) {
												for(var d of c.children) {
													if(d.id === this.$route.params.id) {
														this.activeItem = [d];
														this.openItem = [a, b, c, d];
														break;
													}  else if(d.children) {
														for(var e of d.children) {
															if(e.id === this.$route.params.id) {
																this.activeItem = [e];
																this.openItem = [a, b, c, d, e];
																break;
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			});
		},
		closeMsg() {
			this.$store.commit('MESSAGE', {text: "", type: 'info'});
		},
		getIcon(type) {
			switch(type) {
				case 'structure':
					return 'mdi-domain';
				case 'goup':
					return 'mdi-account-group';
				case 'role':
					return 'mdi-account-hard-hat';
				case 'user':
					return 'mdi-account';
			}

			if(type.startsWith('dafcoCreteilStructure')) return 'mdi-home-city';
		},
		onSearch() {
			this.activeItem = [];
			this.$router.push({name: 'home', query: {token: this.$store.getters.jwt, q: this.keywords}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
		}
	},
	computed: {
		message() {
			return this.$store.getters.message;
		}
	},
	watch: {
		activeItem(val) {
			if(val.length > 0) {
				var item = val[0];

				switch(item.type) {
					case "role":
						this.$router.push({name: 'role', params: {id: item.id}, query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
						break;
					case 'structure':
						this.$router.push({name: 'organisme', params: {id: item.id}, query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
						break;
					case 'goup':
						this.$router.push({name: 'group', params: {id: item.id}, query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
						break;
					case 'user':
						this.$router.push({name: 'user', params: {id: item.id}, query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
						break;
					default:
						if(item.type.startsWith('dafcoCreteilStructure')) this.$router.push({name: 'centre', params: {id: item.id}, query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
						else this.$router.push({name: 'home', query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
				}
			}
		}
	}
};
</script>
