import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				// primary: colors.teal
				primary: '#5770BE',
				secondary: '#91AE4F',
				error: colors.red.darken4,
				warning: colors.orange.darken4,
			}
		}
	}
});
