<template>
	<v-row justify="center">
		<v-dialog v-model="show" persistent max-width="800px">
			<v-card>
				<v-card-title>
					<span v-if="showMode == 'edit'" class="text-h5">Modification d'un utilisateur</span>
					<span v-else class="text-h5">Création d'un utilisateur</span>
				</v-card-title>
				<v-card-text>
					<v-form v-model="valid" ref="form">
						<v-container>
							<v-tabs v-model="tab">
								<v-tab>Général</v-tab>
								<v-tab>Authentification</v-tab>
								<v-tab>Applications</v-tab>
								<v-tab>Rôle</v-tab>
							</v-tabs>

							<v-tabs-items v-model="tab" class="user mt-6">
								<v-tab-item>
									<v-row>
										<v-col cols="12" md="6"><v-text-field v-model="user.firstname" :rules="nameRules" label="Prénom" required /></v-col>
										<v-col cols="12" md="6"><v-text-field v-model="user.lastname" :rules="nameRules" label="Nom" required /></v-col>
									</v-row>
									<v-row>
										<v-col cols="12" md="6"><v-autocomplete v-model="user.orga.id" @change="user.centre.id = null" :rules="nameRules" label="Organisme" :items="filteredOrgas" item-value="id" item-text="fullname" required :disabled="showMode == 'edit'" /></v-col>
										<v-col cols="12" md="6"><v-autocomplete v-model="user.centre.id" :rules="nameRules" label="Structure" :items="centres" item-value="id" item-text="name" required /></v-col>
									</v-row>
									<v-row>
										<v-col cols="12"><v-autocomplete v-model="user.function" :rules="nameRules" label="Fonction" :items="functions" required /></v-col>
										<v-col cols="12" md="6"><v-text-field v-model="user.mail" :rules="nameRules" label="Adresse mail de contact" required /></v-col>
									</v-row>
									<v-row>
										<v-col cols="12" md="6"><v-text-field v-model="user.phone" label="Téléphone" /></v-col>
										<v-col cols="12" md="6"><v-text-field v-model="user.mobile" label="Mobile" /></v-col>
									</v-row>
								</v-tab-item>
								<v-tab-item>
									<v-row>
										<v-col cols="12" md="6"><v-text-field v-show="showMode == 'edit'" v-model="user.uid" label="Identifiant" readonly disabled /></v-col>
									</v-row>
									<v-row>
										<v-col cols="12" md="6"><v-text-field v-model="user.password" @click:append="showPWD = !showPWD" :append-icon="showPWD?'mdi-eye':'mdi-eye-off'" :type="showPWD ? 'text':'password'" :rules="nameRules" label="Mot de passe" required /></v-col>
										<v-col cols="12" md="6"><v-btn color="secondary" @click="generate()" text>Générer</v-btn></v-col>
									</v-row>
									<v-row>
										<v-col cols="12" md="6"> Solidité du nouveau mot de passe : </v-col>
										<v-col cols="12" md="6"> <v-progress-linear height="8" :value="strength" :color="pwdColor"></v-progress-linear></v-col>
									</v-row>
								</v-tab-item>
								<v-tab-item>
									<v-row>
										<v-col cols="12"><v-autocomplete v-model="selectedApp" :search-input.sync="searchApp" label="Application" :items="appItems" item-value="shortname" :item-text="getAppName" /></v-col>
									</v-row>
									<v-row>
										<v-col cols="12"><v-chip class="ma-2" v-for="(item, index) in user.applications" :key="index+'-'+item" close close-icon="mdi-delete" @click:close="user.applications.splice(index, 1)" color="primary">{{item}}</v-chip></v-col>
									</v-row>
								</v-tab-item>
								<v-tab-item>
									<v-row>
										<v-col cols="12"><v-autocomplete v-model="selectedRole" :search-input.sync="searchRole" label="Rôles" :items="roleItems" item-value="code" item-text="label" /></v-col>
									</v-row>
									<v-row>
										<v-col cols="12"><v-chip class="ma-2" small v-for="(item, index) in user.roles" :key="index+'-'+item" close close-icon="mdi-delete" @click:close="user.roles.splice(index, 1)" color="primary">{{getRoleName(item)}}</v-chip></v-col>
									</v-row>
								</v-tab-item>
							</v-tabs-items>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="show = false">Annuler</v-btn>
					<v-btn color="secondary" text @click="save()" outlined>Enregistrer</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { passwordStrength } from 'check-password-strength';
import password from 'secure-random-password';

export default {
	data: () => ({
		showPWD: false,
		valid: false,
		tab: 0,
		nameRules: [
			v => !!v || 'Ce champs est requis'
		],
		organismes: Array(),
		functions: Array(),
		applications: Array(),
		selectedApp: null,
		searchApp: '',
		roles: Array(),
		selectedRole: null,
		searchRole: '',
	}),
	created() {
		this.$axios.get("/applications").then((response) => {
			if(response.data) {
				this.applications = response.data;
			}
		});

		this.$axios.get("/functions").then((response) => {
			if(response.data) {
				this.functions = response.data;
			}
		});

		this.$axios.get("/roles").then((response) => {
			if(response.data) {
				this.roles = response.data;
			}
		});

		this.$axios.get("/organismes").then((response) => {
			if(response.data) {
				this.organismes = response.data;
			}
		});
	},
	methods: {
		save() {
			if(this.valid) {
				if(this.showMode == 'add') {
					this.$axios.post("/users", this.user).then(() => {
						this.$router.go(); // refresh the page
						this.show = false;
					});
				} else {
					this.$axios.patch("/users/" + this.user.uid, this.user).then(() => {
						this.$router.go(); // refresh the page
						this.show = false;
					});
				}
			} else {
				this.$store.commit('MESSAGE', {text: "Merci de remplir tous les champs nécessaires", type: 'warning'});
				this.$refs.form.validate();
			}
		},
		generate() {
			this.user.password = password.randomPassword({length: 10, characters: [password.lower, { characters: password.upper, exactly: 2 }, { characters: password.digits, exactly: 2 }, { characters: '&#@$!?.=+-', exactly: 2 }]});
		},
		getAppName(item) {
			return item.label + " (" + item.shortname + ")";
		},
		getRoleName(code) {
			for(const role of this.roles) {
				if(role.code === code) return role.label;
			}
			return code;
		}
	},
	computed: {
		show: {
			get() {
				return this.$store.state.showModUser != null;
			},
			set(value) {
				if(!value) this.$store.commit('SHOW_MOD_USER', null);
			}
		},
		showMode: {
			get() {
				return this.$store.state.showModUser;
			},
			set(value) {
				this.$store.commit('SHOW_MOD_USER', value);
			}
		},
		user: {
			get() {
				return this.$store.state.editedUser;
			},
			set(value) {
				this.$store.commit('EDIT_USER', value);
			}
		},
		strength() {
			switch(passwordStrength(this.user.password).id) {
				case 0:
					return 0;
				case 1:
					return 35;
				case 2:
					return 60;
				case 3:
					return 100;
				default:
					return 0;
			}
		},
		pwdColor() {
			switch(this.strength) {
				case 0:
					return "red";
				case 35:
					return "orange";
				case 60:
					return "blue";
				case 100:
					return "green";
				default:
					return "red";
			}
		},
		appItems() {
			return this.applications.filter(item => {
				for(const app of this.user.applications) {
					if(app === item.shortname) return false;
				}
				return true;
			});
		},
		roleItems() {
			return this.roles.filter(item => {
				for(const role of this.user.roles) {
					if(role === item.code) return false;
				}
				return true;
			});
		},
		filteredOrgas() {
			if(this.$store.getters.claims.roles.includes("annuaire.administrateur")) {
				return this.organismes;
			} else {
				var listing = Array();
				for(const orga of this.organismes) {
					if(this.$store.getters.claims.roles.includes("gsi."+orga.id)) listing.push(orga);
				}
				return listing;
			}
		},
		centres() {
			for(const orga of this.organismes) {
				if(orga.id === this.user.orga.id) return orga.centres;
			}
			return Array();
		}
	},
	watch: {
		show() {
			if(this.$refs.form) this.$refs.form.resetValidation();
			this.tab = 0;
			this.showPWD = false;
		},
		selectedApp(item) {
			if(item != null) {
				this.$nextTick(() => {
					this.searchApp = '';
					this.selectedApp = null;
					this.user.applications.push(item);
				});
			}
		},
		selectedRole(item) {
			if(item != null) {
				this.$nextTick(() => {
					this.searchRole = '';

					this.selectedRole = null;
					this.user.roles.push(item);
				});
			}
		}
	}
}
</script>

<style lang="scss" scoped>

.user .v-window-item {
	min-height: 480px;
}

</style>
