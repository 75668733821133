<template>
	<v-data-table :headers="headers" :items="items" :items-per-page="-1" dense class="elevation-1 mt-16 mr-4" @click:row="onClickRow">
		<template v-slot:item.fullname="{ item }">
			<v-icon color="primary">{{getIcon(item.type)}}</v-icon> {{ item.name }}
		</template>
	</v-data-table>
</template>

<script>
export default {
	data: () => ({
		headers: [
			{ text: 'Nom', value: 'fullname', align: 'start' },
			{ text: 'Description', value: 'description', align: 'start' },
			{ text: 'Mail', value: 'mail'}
		],
		items: []
	}),
	created() {
		this.init();
	},
	watch: {
		'$route.query.q'() {
			this.init()
		}
	},
	methods: {
		init() {
			if(this.$route.query.q) {
				this.$axios.get("/search", {params: {q: this.$route.query.q}}).then((response) => {
					if(response.data) {
						this.items = response.data;
					}
				});
			} else {
				this.items = [];
			}
		},
		getIcon(type) {
			switch(type) {
				case 'structure':
					return 'mdi-domain';
				case 'goup':
					return 'mdi-account-group';
				case 'role':
					return 'mdi-account-hard-hat';
				case 'user':
					return 'mdi-account';
				case 'centre':
					return 'mdi-home-city';
			}
		},
		onClickRow(item) {
			switch(item.type) {
				case "role":
					this.$router.push({name: 'role', params: {id: item.id}, query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
					break;
				case 'structure':
					this.$router.push({name: 'organisme', params: {id: item.id}, query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
					break;
				case 'goup':
					this.$router.push({name: 'group', params: {id: item.id}, query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
					break;
				case 'user':
					this.$router.push({name: 'user', params: {id: item.id}, query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
					break;
				case 'centre':
					this.$router.push({name: 'centre', params: {id: item.id}, query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
					break;
				default:
					this.$router.push({name: 'home', query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
			}
		}
	}
}
</script>
