"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '@/store'


// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
	baseURL: process.env.VUE_APP_BACKEND || ""
	// timeout: 60 * 1000, // Timeout
	// withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
	function(config) {
		// Do something before request is sent
		return config;
	},
	function(error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// Add a response interceptor

_axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    console.log("ERROR AXIOS : ", error);
	if(error.response) {
		switch(error.response.status) {
			// TODO if error 401 mainOidc.signIn()
			case 409:
				return Promise.reject(error);
			default:
			if(error.response.statusText) store.commit('MESSAGE', {text:  error.response.statusText, type: 'error'});
		}
	} else {
		store.commit('MESSAGE', {text: error, type: 'error'});
	}

	return error;
});

Plugin.install = function(Vue) {
	Vue.axios = _axios;
	window.axios = _axios;
	Object.defineProperties(Vue.prototype, {
		axios: {
			get() {
				return _axios;
			}
		},
		$axios: {
			get() {
				return _axios;
			}
		},
	});
};

Vue.use(Plugin)

export default Plugin;
