<template>
	<v-row justify="center">
		<v-dialog v-model="show" persistent max-width="80%">
			<v-card>
				<v-card-title>
					<span v-if="showMode == 'edit'" class="text-h5">Modification d'un organisme</span>
					<span v-else class="text-h5">Création d'un organisme</span>
				</v-card-title>
				<v-card-text>
					<v-form v-model="valid" ref="form">
						<v-container>
							<v-row v-if="showMode != 'edit'">
								<v-col cols="12" md="2"><v-text-field v-model="orga.id" :rules="idRules" label="Identifiant interne" :readonly="showMode == 'edit'" required /></v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="6"><v-text-field v-model="orga.name" :rules="nameRules" label="Dénomination courte" required /></v-col>
								<v-col cols="12" md="6"><v-text-field v-model="orga.fullname" :rules="nameRules" label="Dénomination longue" required /></v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="6"><v-text-field v-model="orga.SIRET" label="Numéro de SIRET" /></v-col>
								<v-col cols="12" md="6"><v-text-field v-model="orga.UAI" label="UAI" /></v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="6"><v-text-field v-model="orga.IDkelios" label="Identifiant Kélios" /></v-col>
								<v-col cols="12" md="6">
									<v-menu v-model="mndt1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }"><v-text-field :value="frDate(orga.syncKelios)" label="Date de dernière mise à jour" prepend-icon="mdi-calendar" clearable readonly @click:clear="orga.syncKelios = null" v-bind="attrs" v-on="on" /></template>
										<v-date-picker locale="fr-FR" v-model="orga.syncKelios" @input="mndt1 = false" />
									</v-menu>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="6"><v-text-field v-model="orga.numDeclarationActivite" label="Numéro de déclaration d'activité" /></v-col>
								<v-col cols="12" md="6">
									<v-menu v-model="mndt2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }"><v-text-field :value="frDate(orga.dateDebutActivite)" label="Date de début d'activité" prepend-icon="mdi-calendar" clearable readonly @click:clear="orga.dateDebutActivite = null" v-bind="attrs" v-on="on" /></template>
										<v-date-picker locale="fr-FR" v-model="orga.dateDebutActivite" @input="mndt2 = false" />
									</v-menu>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="6"><v-text-field v-model="orga.web" label="URL du site web" /></v-col>
								<v-col cols="12" md="6"><v-checkbox v-model="orga.qualite" label="Certifié Eduform/Qualiopi" /></v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="6"><v-textarea v-model="orga.description" label="Description" /></v-col>
								<!-- <v-col cols="12" md="6"><v-text-field v-model="orga.logo" label="Logo" /></v-col> -->
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="show = false">Annuler</v-btn>
					<v-btn color="secondary" text @click="save()" outlined>Enregistrer</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	data: () => ({
		valid: false,
		idRules: [
			v => !!v || 'Ce champs est requis',
			v => (new RegExp(/^[a-z0-9]+$/i)).test(v) || 'Veuillez utiliser uniquement des caractères alphanumériques',
		],
		nameRules: [
			v => !!v || 'Ce champs est requis',
			// v => v.length <= 10 || 'Name must be less than 10 characters',
		],
		mndt1: false,
		mndt2: false
	}),
	methods: {
		save() {
			if(this.valid) {
				if(this.showMode == 'add') {
					this.$axios.put("/organismes/" + this.orga.id, this.orga).then(() => {
						this.$router.go(); // refresh the page
						this.show = false;
					}).catch((error) => {
						if(error.response.status === 409) {
							this.$store.commit('MESSAGE', {text: "Un organisme avec le même identifiant existe déjà!", type: 'error'});
						}
					});
				} else {
					this.orga.id = this.orga.id.replace(/\s/g,'');
					this.$axios.patch("/organismes/" + this.orga.id, this.orga).then(() => {
						this.$router.go(); // refresh the page
						this.show = false;
					});
				}
			} else {
				this.$store.commit('MESSAGE', {text: "Merci de remplir tous les champs nécessaires", type: 'warning'});
				this.$refs.form.validate();
			}
		},
		frDate(date) {
			if(date) {
				return (new Date(date)).toLocaleString('fr-FR', {timeZone: 'UTC', year: 'numeric', month: "2-digit", day: "2-digit"});
			} else {
				return "";
			}
		}
	},
	computed: {
		show: {
			get() {
				return this.$store.state.showModOrganisme != null;
			},
			set(value) {
				if(!value) this.$store.commit('SHOW_MOD_ORGANISME', null);
			}
		},
		showMode: {
			get() {
				return this.$store.state.showModOrganisme;
			},
			set(value) {
				this.$store.commit('SHOW_MOD_ORGANISME', value);
			}
		},
		orga: {
			get() {
				return this.$store.state.editedOrganisme;
			},
			set(value) {
				this.$store.commit('EDIT_ORGANISME', value);
			}
		}
	},
	watch: {
		show() {
			if(this.$refs.form) this.$refs.form.resetValidation();
		}
	}
}
</script>
