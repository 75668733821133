<template>
	<div>
		<v-row class="my-4">
			<v-col cols="12" md="8">
				<v-card class="mx-sm-8">
					<v-card-title>{{centre.description}}</v-card-title>
					<v-list>
						<v-list-item three-line>
							<v-list-item-avatar tile size="96">
								<v-icon color="secondary" size="96">mdi-home-city</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<!-- <v-list-item-subtitle>id: {{centre.id}}</v-list-item-subtitle> -->
								<v-list-item-subtitle>{{centre.address.name}}</v-list-item-subtitle>
								<v-list-item-subtitle>{{centre.address.street}}</v-list-item-subtitle>
								<v-list-item-subtitle>{{centre.address.postcod}}</v-list-item-subtitle>
								<v-list-item-subtitle>{{centre.address.city}}</v-list-item-subtitle>
								<v-list-item-subtitle>Tél : {{centre.phone}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>

			<v-col cols="12" md="4">
				<v-card class="mx-sm-4" v-if="$store.getters.claims.roles.includes('annuaire.administrateur') || $store.getters.claims.roles.includes('gsi.' + id.substr(0, id.length - 3))">
					<v-card-title>Outils</v-card-title>
					<v-list dense>
						<v-list-item><v-list-item-content><v-list-item-title><v-btn color="primary" @click="$store.dispatch('editCentre', id)" text><v-icon>mdi-pencil</v-icon> Modifier</v-btn></v-list-item-title></v-list-item-content></v-list-item>
						<v-list-item><v-list-item-content><v-list-item-title><v-btn color="primary" @click="$store.dispatch('removeCentre', centre)" text><v-icon>mdi-delete</v-icon> Supprimer</v-btn></v-list-item-title></v-list-item-content></v-list-item>
						<v-list-item><v-list-item-content><v-list-item-title><v-btn color="primary" @click="$store.dispatch('createUser', id)" text><v-icon>mdi-plus-box</v-icon> Ajouter un utilisateur</v-btn></v-list-item-title></v-list-item-content></v-list-item>
					</v-list>
				</v-card>
			</v-col>
		</v-row>

		<v-data-table :headers="headers" :items="items" :items-per-page="-1" dense class="elevation-1 mr-4" @click:row="onClickRow">
			<template v-slot:item.fullname="{ item }">
				<v-icon color="primary">mdi-account</v-icon> {{ item.fullname }}
			</template>
		</v-data-table>
	</div>
</template>

<script>
export default {
	props: {
		id: String
	},
	data: () => ({
		headers: [
			{ text: 'Nom', value: 'fullname', align: 'start' },
			{ text: 'Description', value: 'description', align: 'start' },
			{ text: 'Mail', value: 'mail'}
		],
		items: [],
		centre: {
			id: "",
			name: "",
			description: "",
			phone: "",
			address: {
				name: "",
				street: "",
				codpost: "",
				city: ""
			}
		}
	}),
	created() {
		this.init();
	},
	watch: {
		id() {
			this.init()
		}
	},
	methods: {
		init() {
			this.$axios.get("/centres/" + this.id + "/users").then((response) => {
				if(response.data) {
					this.centre = response.data.centre;
					this.items = response.data.users;
				}
			});
		},
		onClickRow(item) {
			this.$router.push({name: 'user', params: {id: item.uid}, query: {token: this.$store.getters.jwt}}).catch(e => {(e.name === 'NavigationDuplicated'?'':console.log(e))});
		}
	}
}
</script>
