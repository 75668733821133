<template>
	<v-row justify="center">
		<v-dialog v-model="show" persistent max-width="800px">
			<v-card>
				<v-card-title>
					<span class="text-h5">Suppression d'un utilisateur</span>
				</v-card-title>
				<v-card-text v-if="conflict">
					L'utilisateur <b>{{user.firstname}} {{user.lastname}} ({{user.uid}})</b> ne peut pas être supprimé car il est référencé par les actions de formations suivantes :
					<ul>
						<li v-for="(action, index) in actions" :key="index">{{action}}</li>
					</ul>
				</v-card-text>
				<v-card-text v-else>
					Êtes-vous sûr de vouloir supprimer l'utilisateur <b>{{user.firstname}} {{user.lastname}} ({{user.uid}})</b> ?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn v-show="!conflict" color="primary" text @click="show = false">Annuler</v-btn>
					<v-btn v-show="!conflict" color="secondary" text @click="remove()" outlined>Supprimer</v-btn>
					<v-btn v-show="conflict" color="secondary" text @click="show = false" outlined>Ok</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	data: () => ({
		conflict: false,
		actions: []
	}),
	methods: {
		remove() {
			this.$axios.delete("/users/" + this.user.uid).then(() => {
				this.$router.go(); // refresh the page
				this.show = false;
			}).catch((error) => {
				if(error.response.status === 409) {
					this.conflict = true;
					this.actions = error.response.data;
				}
			});
		}
	},
	computed: {
		show: {
			get() {
				return this.$store.state.showRemoveUser != null;
			},
			set(value) {
				if(!value) {
					this.conflict = false;
					this.actions = [];
					this.$store.commit('SHOW_REMOVE_USER', null);
				}
			}
		},
		user: {
			get() {
				if(this.$store.state.showRemoveUser != null) return this.$store.state.showRemoveUser;
				else return {};
			}
		}
	}
}
</script>

<style lang="scss" scoped>

// .user .v-window-item {
// 	min-height: 480px;
// }

</style>
