<template>
	<v-row justify="center">
		<v-dialog v-model="show" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span v-if="showMode == 'edit'" class="text-h5">Modification d'une structure</span>
					<span v-else class="text-h5">Création {{type}}</span>
				</v-card-title>
				<v-card-text>
					<v-form v-model="valid" ref="form">
						<v-container>
							<v-tabs v-model="tab">
								<v-tab>Général</v-tab>
								<v-tab>Adresse</v-tab>
								<v-tab v-if="hasAccueil">Accueil</v-tab>
								<v-tab v-if="hasCentre">Centre</v-tab>
								<v-tab v-if="hasKelios">Kelios</v-tab>
							</v-tabs>

							<v-tabs-items v-model="tab" class="user mt-6">
								<v-tab-item>
									<v-row>
										<v-col cols="12" md="12"><v-autocomplete v-model="centre.idStructure" :rules="nameRules" label="Organisme" :items="filteredOrgas" item-value="id" item-text="fullname" required :disabled="showMode == 'edit'" /></v-col>
									</v-row>
									<v-row>
										<!-- <v-col v-if="showMode == 'edit'" cols="12" md="2"><v-text-field v-model="centre.id" label="Identifiant interne" :readonly="showMode == 'edit'" :disabled="showMode == 'edit'" required /></v-col> -->
										<v-col v-if="showMode == 'edit'" cols="12" md="6"><v-text-field v-model="centre.name" :rules="nameRules" label="Dénomination courte" required /></v-col>
										<v-col v-else cols="12" md="6"><v-text-field v-model="centre.name" :rules="nameRules" label="Dénomination courte" required /></v-col>
										<v-col cols="12" md="6"><v-text-field v-model="centre.fullname" :rules="nameRules" label="Dénomination longue" required /></v-col>
									</v-row>
									<v-row>
										<v-col cols="12" md="6"><v-text-field v-model="centre.phone" label="Téléphone" /></v-col>
										<v-col cols="12" md="6"><v-text-field v-model="centre.mail" label="Adresse mail" /></v-col>
									</v-row>
									<v-row>
										<v-col cols="12" md="12"><v-textarea v-model="centre.description" label="Description" /></v-col>
									</v-row>
								</v-tab-item>
								<v-tab-item>
									<v-row dense>
										<v-col cols="12" md="12"><v-autocomplete v-model="centre.lieu" :rules="nameRules" label="Lieu géographique" :items="lieux" return-object :item-text="getLieuName" required /></v-col>
										<!-- <v-col cols="12" md="3"><v-text-field v-model="centre.lieu.id" label="ID" readonly disabled /></v-col> -->
										<v-col cols="12" md="12"><v-text-field v-model="centre.lieu.name" label="Nom" :rules="nameRules" required /></v-col>
										<v-col cols="12"><v-text-field v-model="centre.lieu.label" label="Nom de l'étiquette postale" /></v-col>
										<v-col cols="12" md="12"><v-textarea v-model="centre.lieu.street" outlined label="Rue" rows="3" :rules="nameRules" required  dense /></v-col>
										<v-col cols="12" md="4"><v-text-field v-model="centre.lieu.postcod" label="Code postal" :rules="nameRules" required  /></v-col>
										<v-col cols="12" md="8"><v-text-field v-model="centre.lieu.city" label="Ville" :rules="nameRules" required  /></v-col>
										<v-col cols="12" md="6"><v-text-field v-model="centre.lieu.latitude" label="Latitude" /></v-col>
										<v-col cols="12" md="6"><v-text-field v-model="centre.lieu.longitude" label="Longitude" /></v-col>
										<v-col cols="12Créer une nouvelle adresse" md="12"><v-textarea v-model="centre.lieu.description" label="Description" dense outlined rows="3" /></v-col>
									</v-row>
								</v-tab-item>
								<v-tab-item v-if="hasAccueil">
									<v-row dense>
										<v-col cols="12" md="12"><v-text-field v-model="centre.horairesAccueil" :rules="nameRules" label="Horaire d'ouverture au public" required /></v-col>
										<v-col cols="12" md="12"><v-checkbox v-model="centre.isAccessible" label="Site accessible aux personnes en situation de handicap" /></v-col>
									</v-row>
									<v-row v-show="centre.isAccessible" dense>
										<v-col cols="12"><v-autocomplete v-model="selectedAmgnt" :search-input.sync="searchAmgnt" label="Types d'aménagement disponibles" :items="amgntItems" item-text="label" return-object /></v-col>
										<v-col cols="12"><v-chip class="ma-2" small v-for="(item, index) in centre.typeAccessible" :key="index+'-'+item.id" close close-icon="mdi-delete" @click:close="centre.typeAccessible.splice(index, 1)" color="primary">{{getAccessName(item)}}</v-chip></v-col>
									</v-row>
									<v-row v-show="centre.isAccessible" dense>
										<v-col cols="12" md="12"><v-textarea v-model="centre.autreAccessible" label="Autres aménagements" /></v-col>
									</v-row>
								</v-tab-item>
								<v-tab-item v-if="hasCentre">
									<v-row dense>
										<v-col cols="12" md="6"><v-checkbox v-model="centre.serviceRestauration" label="Service Restauration" /></v-col>
										<v-col cols="12" md="6"><v-checkbox v-model="centre.serviceHebergement" label="Service Hébergement" /></v-col>
									</v-row>
									<v-card class="mx-auto" outlined>
										<v-card-subtitle>Moyens pédagogiques</v-card-subtitle>
										<v-card-text>
											<v-row dense>
												<v-col cols="12" md="5"><v-text-field v-model="equipement.name" label="Nom" /></v-col>
												<v-col cols="12" md="2"><v-select v-model="equipement.type" :items="typesEquipement" label="Type" /></v-col>
												<v-col cols="12" md="3"><v-text-field v-model="equipement.capacity" type="number" min="0" label="Capacité" /></v-col>
												<v-col cols="12" md="2"><v-btn color="primary" @click="addEquipment"><v-icon>mdi-plus</v-icon></v-btn></v-col>
												<v-col cols="12"><v-chip class="ma-2" small v-for="(item, index) in centre.equipements" :key="index+'-'+item.name+'-'+item.type" close close-icon="mdi-delete" @click:close="centre.equipements.splice(index, 1)" color="primary">{{item.name}}</v-chip></v-col>
											</v-row>
										</v-card-text>
									</v-card>
									<v-row dense>
										<v-col cols="12" md="12"><v-textarea v-model="centre.serviceCommentaire" label="Commentaire sur les services" /></v-col>
									</v-row>
								</v-tab-item>
								<v-tab-item v-if="hasKelios">
									<v-row>
										<v-col cols="12" md="12"><v-text-field v-model="centre.IDkelios" label="Identifiant Kélios" /></v-col>
										<v-col cols="12" md="12">
											<v-menu v-model="mndt" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
												<template v-slot:activator="{ on, attrs }"><v-text-field :value="frDate(centre.syncKelios)" label="Date de dernière mise à jour" prepend-icon="mdi-calendar" clearable readonly @click:clear="centre.syncKelios = null" v-bind="attrs" v-on="on" /></template>
												<v-date-picker locale="fr-FR" v-model="centre.syncKelios" @input="mndt = false" />
											</v-menu>
										</v-col>
									</v-row>
								</v-tab-item>
							</v-tabs-items>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="show = false">Annuler</v-btn>
					<v-btn color="secondary" text @click="save()" outlined>Enregistrer</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	data: () => ({
		tab: 0,
		valid: false,
		nameRules: [
			v => !!v || 'Ce champs est requis',
		],
		mndt: false,
		organismes: Array(),
		lieux: Array(),
		amenagements: Array(),
		selectedAmgnt: null,
		searchAmgnt: '',
		equipement: {
			name: '',
			type: '',
			capacity: '',
			description: ''
		},
		typesEquipement: [
			'Salle de formation',
			'Salle informatique',
			'Centre de ressource',
			'plateau technique',
			'Autre'
		]
	}),
	created() {
		this.$axios.get("/amenagements").then((response) => {
			if(response.data) {
				this.amenagements = response.data;
			}
		});

		this.$axios.get("/organismes").then((response) => {
			if(response.data) {
				this.organismes = response.data;
			}
		});
	},
	methods: {
		save() {
			if(this.valid) {
				if(this.showMode == 'add') {
					this.$axios.post("/centres", this.centre).then(() => {
						this.$router.go(); // refresh the page
						this.show = false;
					});
				} else {
					this.$axios.patch("/centres/" + this.centre.id, this.centre).then(() => {
						this.$router.go(); // refresh the page
						this.show = false;
					});
				}
			} else {
				this.$store.commit('MESSAGE', {text: "Merci de remplir tous les champs nécessaires", type: 'warning'});
				this.$refs.form.validate();
			}
		},
		frDate(date) {
			if(date) {
				return (new Date(date)).toLocaleString('fr-FR', {timeZone: 'UTC', year: 'numeric', month: "2-digit", day: "2-digit"});
			} else {
				return "";
			}
		},
		getAccessName(item) {
			for(const amenagement of this.amenagements) {
				if(amenagement.id === item.id) return amenagement.label;
			}
			return item.label;
		},
		addEquipment() {
			if(this.equipement.name.trim() === '') {
				this.$store.commit('MESSAGE', {text: "Le nom du moyen pédagogique est obligatoire", type: 'error'});
			} else {
				this.centre.equipements.push(this.equipement);
				this.equipement = {
					name: '',
					type: '',
					capacity: '',
					description: ''
				};
			}
		},
		getLieuName(lieu) {
			if(lieu.id === null) return "Créer une nouvelle adresse";
			else return lieu.name;
		}
	},
	computed: {
		show: {
			get() {
				return this.$store.state.showModCentre != null;
			},
			set(value) {
				if(!value) this.$store.commit('SHOW_MOD_CENTRE', null);
			}
		},
		showMode: {
			get() {
				return this.$store.state.showModCentre;
			},
			set(value) {
				this.$store.commit('SHOW_MOD_CENTRE', value);
			}
		},
		centre: {
			get() {
				return this.$store.state.editedCentre;
			},
			set(value) {
				this.$store.commit('EDIT_CENTRE', value);
			}
		},
		type() {
			switch(this.centre.type) {
				case "dafcoCreteilStructureAgenceCommerciale":
					return "Agence commerciale";
				case "dafcoCreteilStructureAccueil":
					return "Point accueil";
				case "dafcoCreteilStructureAgenceComptable":
					return "Agence comptable";
				case "dafcoCreteilStructureCentre":
					return "Centre de formation";
				case "dafcoCreteilStructureService":
					return "Service administratif";
				default:
					return this.centre.type;
			}
		},
		filteredOrgas() {
			if(this.$store.getters.claims.roles.includes("annuaire.administrateur")) {
				return this.organismes;
			} else {
				var listing = Array();
				for(const orga of this.organismes) {
					if(this.$store.getters.claims.roles.includes("gsi."+orga.id)) listing.push(orga);
				}
				return listing;
			}
		},
		hasAccueil() {
			return this.centre.type === 'dafcoCreteilStructureAgenceCommerciale'  || this.centre.type === 'dafcoCreteilStructureAccueil'  || this.centre.type === 'dafcoCreteilStructureCentre';
		},
		hasCentre() {
			return this.centre.type === 'dafcoCreteilStructureCentre';
		},
		hasKelios() {
			return this.centre.type === 'dafcoCreteilStructureAgenceCommerciale'  || this.centre.type === 'dafcoCreteilStructureAccueil'  || this.centre.type === 'dafcoCreteilStructureCentre';
		},
		amgntItems() {
			return this.amenagements.filter(item => {
				for(const access of this.centre.typeAccessible) {
					if(access.id === item.id) return false;
				}
				return true;
			});
		},
	},
	watch: {
		show() {
			if(this.$refs.form) this.$refs.form.resetValidation();
			this.tab = 0;
		},
		selectedAmgnt(item) {
			if(item != null) {
				this.$nextTick(() => {
					this.searchAmgnt = '';

					this.selectedAmgnt = null;
					this.centre.typeAccessible.push(item);
				});
			}
		},
		"centre.idStructure"() {
			if(this.centre.idStructure != null) {
				this.$axios.get("/organismes/" + this.centre.idStructure + "/lieux").then((response) => {
					if(response.data) {
						this.lieux = [{id: null}].concat(response.data);
					}
				});
			}
		},
		"centre.lieu.id"(val) {
			if(val === null) {
				this.centre.lieu = {id: null};
			}
		}
	}
}
</script>

<style lang="scss" scoped>

.user .v-window-item {
	min-height:660px;
}

</style>
